import React from 'react';

import { Typography } from '@material-ui/core';
import { Layout, SEO } from 'components';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Typography variant='h1' display='block'>
      Page not found
    </Typography>
    <Typography variant='h5' display='block'>
      You just hit a route that doesn't exist...the sadness
    </Typography>
  </Layout>
);

export default NotFoundPage;
